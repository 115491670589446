import React, { Dispatch, useEffect } from "react";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  FormProps,
  Input,
  Select,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyThunkType,
  getCallraillCompaneies,
  getCompany,
  updateCompany,
  updateCompanyTrade,
} from "../store/actions/companyActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";
import { CompanyTrade } from "../components/companies/CompanyTrade";
import {
  setCheckedAppliances,
  setCheckedBrandAppliances,
  setCheckedCategories,
} from "../store/actions/categoriesActions";

type FieldType = {
  name: string;
  logo?: string;
  slogan?: string;
  workingHours?: string;
  warranty?: string;
  offers?: string;
  requestServiceUrl?: string;
  destinationPhoneNumber?: string;
  callrailCompanyId?: string;
};

export const Company = () => {
  const dispatch = useDispatch() as Dispatch<CompanyThunkType | AnyAction>;
  let { id } = useParams();
  const [form] = Form.useForm();
  const { Title } = Typography;
  const company = useSelector((state: AppStateType) => state.company.company);
  const isCompanyCreatingInProgress = useSelector(
    (state: AppStateType) => state.company.isCompanyCreatingInProgress
  );
  const isCompanyTradeUpdateInProgress = useSelector(
    (state: AppStateType) => state.company.isCompanyTradeUpdateInProgress
  );
  const isCompaniesLoadingInProgress = useSelector(
    (state: AppStateType) => state.company.isCompaniesLoadingInProgress
  );
  const isCallrailCompaniesLoadingInProgress = useSelector(
    (state: AppStateType) => state.company.isCallrailCompaniesLoadingInProgress
  );
  const callrailCompanies = useSelector(
    (state: AppStateType) => state.company.callrailCompanies
  );

  console.log("id", id);

  useEffect(() => {
    if (id) {
      dispatch(getCompany(id));
      dispatch(getCallraillCompaneies());
    }
  }, [id]);

  useEffect(() => {
    if (company?._id) {
      form.setFieldValue("name", company.name);
      if (company.logo) {
        form.setFieldValue("logo", company.logo);
      }
      if (company.offers) {
        form.setFieldValue("offers", company.offers);
      }
      if (company.slogan) {
        form.setFieldValue("slogan", company.slogan);
      }
      if (company.warranty) {
        form.setFieldValue("warranty", company.warranty);
      }
      if (company.workingHours) {
        form.setFieldValue("workingHours", company.workingHours);
      }
      if (company.requestServiceUrl) {
        form.setFieldValue("requestServiceUrl", company.requestServiceUrl);
      }
      if (company.callrailCompanyId) {
        form.setFieldValue("callrailCompanyId", company.callrailCompanyId);
      }
      if (company.destinationPhoneNumber) {
        form.setFieldValue(
          "destinationPhoneNumber",
          company.destinationPhoneNumber
        );
      }
      dispatch(setCheckedCategories(company.checkedCategories));
      dispatch(setCheckedAppliances(company.checkedAppliances));
      dispatch(setCheckedBrandAppliances(company.checkedBrandAppliances));
    }
  }, [company?._id, callrailCompanies]);

  if (isCompaniesLoadingInProgress) {
    return <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />;
  }

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (company && company?._id) {
      dispatch(updateCompany({ ...values, _id: company._id }));
    }
  };

  return company ? (
    <>
      <div
        style={{
          display: "flex",
          padding: 20,
          paddingTop: 10,
          flexDirection: "column",
        }}
      >
        <Breadcrumb
          items={[
            {
              href: "/companies",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Companies</span>
                </>
              ),
            },
            {
              title: company?.name,
            },
          ]}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              paddingRight: 30,
            }}
          >
            <Title level={3}>Base info</Title>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item<FieldType>
                label="Company name"
                name="name"
                rules={[
                  { required: true, message: "Please input company name" },
                ]}
              >
                <Input size="middle" placeholder="Enter company name" />
              </Form.Item>

              <Form.Item<FieldType> label="Working hours" name="workingHours">
                <Input size="middle" placeholder="Enter working hours" />
              </Form.Item>
              <Form.Item<FieldType> label="Warranty" name="warranty">
                <Input size="middle" placeholder="Enter warranty" />
              </Form.Item>
              <Form.Item<FieldType> label="Offers" name="offers">
                <Input size="middle" placeholder="Enter offers" />
              </Form.Item>
              <Form.Item<FieldType>
                label="Destination Email"
                name="requestServiceUrl"
              >
                <Input size="middle" placeholder="Enter url" />
              </Form.Item>
              <Form.Item<FieldType>
                label="Destination Phone Number"
                name="destinationPhoneNumber"
              >
                <Input size="middle" placeholder="Enter phone" />
              </Form.Item>
              <Form.Item<FieldType>
                label="Callrail Company"
                name="callrailCompanyId"
              >
                <Select
                  disabled={isCallrailCompaniesLoadingInProgress}
                  loading={isCallrailCompaniesLoadingInProgress}
                  showSearch
                  placeholder="Select a company"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    ...callrailCompanies?.map((cc) => ({
                      value: cc.id,
                      label: cc.name,
                    })),
                  ]}
                />
              </Form.Item>
              <Button
                style={{ width: 150 }}
                htmlType="submit"
                loading={isCompanyCreatingInProgress}
                type="primary"
                size="middle"
                disabled={false}
              >
                Save
              </Button>
            </Form>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              paddingLeft: 30,
              borderLeft: "1px solid black",
            }}
          >
            <Title level={3}>Company trade</Title>
            <CompanyTrade />
            {id && (
              <Button
                style={{ width: 150, marginTop: 20 }}
                onClick={() => (id ? dispatch(updateCompanyTrade(id)) : null)}
                loading={isCompanyTradeUpdateInProgress}
                type="primary"
                size="middle"
              >
                Save trade
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
};
