import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_APPLIANCES,
  SET_IS_GETTING_APPLIANCES_IN_PROGRESS,
  SET_IS_GETTING_BRANDS_IN_PROGRESS,
  SET_BRANDS,
  SET_IS_EDITING_APPLIANCE_IN_PROGRESS,
} from "../reducers/applianceReducer";
import { applianceApi } from "../../api";
import { notification } from "antd";
import { IAppliance, IBrandAppliance } from "../../types";

type SetIsGettingAppliancesInProgress = {
  type: typeof SET_IS_GETTING_APPLIANCES_IN_PROGRESS;
  payload: { isGettingAppliancesInProgress: boolean };
};

type SetIsGettingBrandsInProgress = {
  type: typeof SET_IS_GETTING_BRANDS_IN_PROGRESS;
  payload: { isGettingBrandsInProgress: boolean };
};

type SetAppliances = {
  type: typeof SET_APPLIANCES;
  payload: { appliances: IAppliance[] };
};

type SetBrands = {
  type: typeof SET_BRANDS;
  payload: { applianceBrands: IBrandAppliance[] };
};

type SetIsEditingApplianceInProgress = {
  type: typeof SET_IS_EDITING_APPLIANCE_IN_PROGRESS;
  payload: { isEditingApplianceInProgress: boolean };
};

export type ApplianceActionsTypes =
  | SetIsGettingAppliancesInProgress
  | SetAppliances
  | SetIsGettingBrandsInProgress
  | SetBrands
  | SetIsEditingApplianceInProgress;

export const setIsGettingAppliancesInProgress = (
  isGettingAppliancesInProgress: boolean
): SetIsGettingAppliancesInProgress => ({
  type: SET_IS_GETTING_APPLIANCES_IN_PROGRESS,
  payload: { isGettingAppliancesInProgress },
});

export const setIsEditingApplianceInProgress = (
  isEditingApplianceInProgress: boolean
): SetIsEditingApplianceInProgress => ({
  type: SET_IS_EDITING_APPLIANCE_IN_PROGRESS,
  payload: { isEditingApplianceInProgress },
});

export const setIsGettingBrandsInProgress = (
  isGettingBrandsInProgress: boolean
): SetIsGettingBrandsInProgress => ({
  type: SET_IS_GETTING_BRANDS_IN_PROGRESS,
  payload: { isGettingBrandsInProgress },
});

export const setAppliances = (appliances: IAppliance[]): SetAppliances => ({
  type: SET_APPLIANCES,
  payload: { appliances },
});

export const setBrands = (applianceBrands: IBrandAppliance[]): SetBrands => ({
  type: SET_BRANDS,
  payload: { applianceBrands },
});

export type ApplianceThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  ApplianceActionsTypes
>;

export const getAppliances =
  (categoryId: string): ApplianceThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsGettingAppliancesInProgress(true));
      const response = await applianceApi.loadAppliancesByCategory(categoryId);
      if (response.status === "success") {
        dispatch(setAppliances(response.appliances));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of appliances",
      });
    } finally {
      dispatch(setIsGettingAppliancesInProgress(false));
    }
  };

export const getAllAppliances =
  (): ApplianceThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsGettingAppliancesInProgress(true));
      const response = await applianceApi.loadAppliances();
      if (response.status === "success") {
        dispatch(setAppliances(response.appliances));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of appliances",
      });
    } finally {
      dispatch(setIsGettingAppliancesInProgress(false));
    }
  };

export const createAppliance =
  (values: { [param: string]: string }): ApplianceThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsEditingApplianceInProgress(true));
      const response = await applianceApi.createAppliance(values);
      if (response.status === "success") {
        dispatch(getAppliances(values.category));
        notification.success({
          message: "Success",
          description: "Updated",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of appliances",
      });
    } finally {
      dispatch(setIsEditingApplianceInProgress(false));
    }
  };

export const editAppliance =
  (
    applianceId: string,
    values: { [param: string]: string },
    activeCategory: string
  ): ApplianceThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsEditingApplianceInProgress(true));
      const response = await applianceApi.updAppliance(applianceId, values);
      if (response.status === "success") {
        dispatch(getAppliances(activeCategory));
        notification.success({
          message: "Success",
          description: "Updated",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of appliances",
      });
    } finally {
      dispatch(setIsEditingApplianceInProgress(false));
    }
  };

export const getApplianceBrands =
  (subCategoryId: string): ApplianceThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsGettingBrandsInProgress(true));
      const response = await applianceApi.loadBrandsBySubcategory(
        subCategoryId
      );
      if (response.status === "success") {
        dispatch(setBrands(response.brands));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of brands",
      });
    } finally {
      dispatch(setIsGettingBrandsInProgress(false));
    }
  };

export const getAllApplianceBrands =
  (): ApplianceThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsGettingBrandsInProgress(true));
      const response = await applianceApi.loadAllBrandAppliances();
      if (response.status === "success") {
        dispatch(setBrands(response.brands));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of brands",
      });
    } finally {
      dispatch(setIsGettingBrandsInProgress(false));
    }
  };
