import React, { Dispatch, useEffect, useState } from "react";
import { FieldType, IAppliance } from "../../types";
import { Button, Form, FormProps, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import {
  ApplianceThunkType,
  editAppliance,
} from "../../store/actions/applianceActions";
import { AppStateType } from "../../store";

interface IProps {
  activeAppliance: string;
  appliances: IAppliance[];
  activeCategory: string;
}

export const EditCategoryInput = ({
  activeAppliance,
  appliances,
  activeCategory,
}: IProps) => {
  const dispatch = useDispatch() as Dispatch<ApplianceThunkType | AnyAction>;
  const [form] = Form.useForm();
  const [foundAppliance, setFoundAppliance] = useState<null | IAppliance>(null);
  const isEditingApplianceInProgress = useSelector(
    (state: AppStateType) => state.appliance.isEditingApplianceInProgress
  );
  console.log("activeAppliance", activeAppliance);
  console.log("appliances", appliances);

  useEffect(() => {
    form.setFieldValue("name", foundAppliance?.name);
  }, [foundAppliance]);

  useEffect(() => {
    const fa = appliances?.find((a) => a._id === activeAppliance);
    if (fa) {
      setFoundAppliance(fa);
    }
  }, [appliances, activeAppliance]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);

    dispatch(editAppliance(activeAppliance, values, activeCategory));
  };

  return (
    <div>
      <Form
        onFinish={onFinish}
        form={form}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Form.Item<FieldType> name="name">
          <Input style={{ width: 200 }} size="middle" />
        </Form.Item>
        <Button
          style={{ marginLeft: 10 }}
          htmlType="submit"
          loading={isEditingApplianceInProgress}
          type="primary"
          size="middle"
          disabled={false}
        >
          Save
        </Button>
      </Form>
    </div>
  );
};
