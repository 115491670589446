export const mainUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3755/"
    : "https://sitegen-back.localigniter.com/";

export const siteStatuses = [
  { name: "initial", order: 1 },
  { name: "with_domain", order: 2 },
  { name: "with_content", order: 3 },
  { name: "with_html", order: 4 },
];

export const defaultFonts = [
  {
    value: "'Roboto', sans-serif",
    label: "'Roboto', sans-serif",
  },
  {
    value: "'Open Sans', sans-serif",
    label: "'Open Sans', sans-serif",
  },
  {
    value: "'Lato', sans-serif",
    label: "'Lato', sans-serif",
  },
  {
    value: "'Montserrat', sans-serif",
    label: "'Montserrat', sans-serif",
  },
  {
    value: "'Poppins', sans-serif",
    label: "'Poppins', sans-serif",
  },
  {
    value: "'Merriweather', serif",
    label: "'Merriweather', serif",
  },
  {
    value: "'Nunito', sans-serif",
    label: "'Nunito', sans-serif",
  },
  {
    value: "'PT Sans', sans-serif",
    label: "'PT Sans', sans-serif",
  },
  {
    value: "'Raleway', sans-serif",
    label: "'Raleway', sans-serif",
  },
  {
    value: "'Playfair Display', serif",
    label: "'Playfair Display', serif",
  },
];
