import { Button, Form, FormProps, Input } from "antd";
import React, { Dispatch, useEffect } from "react";
import { FieldType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import { addDomains, DomainThunkType } from "../../store/actions/domainActions";
import { AnyAction } from "redux";

interface IProps {
  activeCategory: string;
}

export const DomainListTextArea = ({ activeCategory }: IProps) => {
  const dispatch = useDispatch() as Dispatch<DomainThunkType | AnyAction>;
  const [form] = Form.useForm();
  const isDomainsLoadingInProgress = useSelector(
    (state: AppStateType) => state.domain.isDomainsLoadingInProgress
  );
  const isDomainsCreatingInProgress = useSelector(
    (state: AppStateType) => state.domain.isDomainsCreatingInProgress
  );
  const domains = useSelector((state: AppStateType) => state.domain.domains);
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values.domains.split("\n"));
    const domains = values.domains.split("\n");

    dispatch(addDomains(domains, activeCategory));

    // dispatch(createAppliance({ ...values, category: activeCategory }));
    form.setFieldValue("domains", "");
  };
  return (
    <>
      <h3>Add domains</h3>
      <Form onFinish={onFinish} form={form}>
        <Form.Item<FieldType> name="domains">
          <Input.TextArea
            placeholder="Domains list"
            style={{ width: "100%" }}
            size="middle"
          />
        </Form.Item>
        <Button
          htmlType="submit"
          loading={isDomainsCreatingInProgress}
          type="primary"
          size="middle"
          disabled={false}
        >
          Add
        </Button>
      </Form>
      <h3>Domains</h3>
      {isDomainsLoadingInProgress && <div>Loading domains...</div>}
      {domains.length === 0 && <div>no domains</div>}
      {domains.map((d) => (
        <>
          <div>
            {d.domain} - {d.isUsed ? "USED" : "NOT USED"} -{" "}
            {d.awsS3Bucket && (
              <span style={{ color: "green" }}>FOLDER READY TO DEPLOY</span>
            )}
          </div>
          -------
        </>
      ))}
    </>
  );
};
