import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  FormProps,
  Input,
  Pagination,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { addTag, getTags, TagThunkType } from "../store/actions/tagActions";
import { useSearchParams } from "react-router-dom";
import { AppStateType } from "../store";
import {
  LoadingOutlined,
  FileImageOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";

import TextArea from "antd/es/input/TextArea";
import { getBeautifullDate } from "../helpers/helpers";
import { FieldType } from "../types";

export const Tags = () => {
  const { Link, Title } = Typography;

  const [form] = Form.useForm();
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const dispatch = useDispatch() as Dispatch<TagThunkType | AnyAction>;
  const [currentSearchParams, setCurrentSearchParams] = useState<{
    [param: string]: string;
  }>();
  const [isGenerateByAi, setIsGenerateByAi] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isAddTag, setIsAddTag] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setCurrentSearchParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);
  useEffect(() => {
    dispatch(
      getTags(currentSearchParams?.page ? Number(currentSearchParams?.page) : 1)
    );
  }, [currentSearchParams?.page]);
  const totalTags = useSelector((state: AppStateType) => state.tag.totalTags);
  const tags = useSelector((state: AppStateType) => state.tag.tags);
  const isTagAddingInProgress = useSelector(
    (state: AppStateType) => state.tag.isTagAddingInProgress
  );
  const isTagsLoadingInProgress = useSelector(
    (state: AppStateType) => state.tag.isTagsLoadingInProgress
  );
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    dispatch(
      addTag(
        values.tagName,
        values.fieldInDb,
        isGenerateByAi,
        values.aiPromt,
        isImage,
        form
      )
    );
  };

  const onPageChange = (current: number) => {
    setSearchParams({ ...currentSearchParams, page: `${current}` });
  };
  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsGenerateByAi(e.target.checked);
  };
  const onChangeIsImage: CheckboxProps["onChange"] = (e) => {
    setIsImage(e.target.checked);
  };
  useEffect(() => {
    setColumns([
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Tag name",
        dataIndex: "tagName",
        key: "tagName",
      },
      {
        title: "Field name in database",
        dataIndex: "fieldInDb",
        key: "fieldInDb",
      },
      {
        title: "Is Generate By Ai?",
        dataIndex: "isGenerateByAi",
        key: "isGenerateByAi",
      },
      {
        title: "Is image?",
        dataIndex: "isImage",
        key: "isImage",
      },
      {
        title: "Is depends?",
        dataIndex: "isDepends",
        key: "isDepends",
      },
      {
        title: "Created",
        dataIndex: "created",
        key: "created",
      },
      {
        title: "Updated",
        dataIndex: "updated",
        key: "updated",
      },
    ]);
  }, []);
  useEffect(() => {
    const ds = [] as any[];
    tags.forEach((t) => {
      ds.push({
        key: t._id,
        id: <Link href={`/tags/${t._id}`}>{t._id}</Link>,
        tagName: t.tagName,
        fieldInDb: t.fieldInDb,
        isGenerateByAi: t.isGenerateByAi ? (
          <>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            {" / "}
            <Tooltip placement="topLeft" title={t.aiPromt}>
              <Link>promt</Link>
            </Tooltip>
          </>
        ) : (
          <CloseCircleTwoTone twoToneColor="red" /> || (
            <CloseCircleTwoTone twoToneColor="red" />
          )
        ),
        isImage: t.isImage ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" /> || (
            <CloseCircleTwoTone twoToneColor="red" />
          )
        ),
        isDepends: t.isDepends ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" /> || (
            <CloseCircleTwoTone twoToneColor="red" />
          )
        ),
        created: getBeautifullDate(t.createdAt),
        updated: getBeautifullDate(t.updatedAt),
      });
    });
    setDataSource(ds);
  }, [tags]);
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ padding: 20 }}>
        <Title level={3}>Template Tags</Title>
        {!isAddTag && (
          <Button
            onClick={() => setIsAddTag(true)}
            style={{ width: 150, marginTop: 4 }}
            type="primary"
            size="middle"
            disabled={false}
          >
            New tag
          </Button>
        )}
        {isAddTag && (
          <>
            <Title level={4}>Add new tag</Title>
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item<FieldType>
                label="Tag name (e.g. company-name)"
                name="tagName"
                style={{ marginRight: 10 }}
              >
                <Input
                  style={{ width: 250 }}
                  size="middle"
                  placeholder="Enter tag name"
                />
              </Form.Item>
              <Form.Item<FieldType>
                label="Field name in site database"
                name="fieldInDb"
                style={{ marginRight: 10 }}
              >
                <Input
                  style={{ width: 250 }}
                  size="middle"
                  placeholder="Enter field name"
                />
              </Form.Item>

              <Checkbox
                style={{ marginBottom: 10 }}
                onChange={onChange}
                checked={isGenerateByAi}
              >
                Generate by AI
              </Checkbox>
              {isGenerateByAi && (
                <>
                  <Form.Item<FieldType> label="Promt for AI" name="aiPromt">
                    <TextArea
                      rows={4}
                      placeholder="Enter promt for AI generation"
                    />
                  </Form.Item>
                  <Checkbox
                    style={{ marginBottom: 20 }}
                    onChange={onChangeIsImage}
                    checked={isImage}
                  >
                    <FileImageOutlined /> Image
                  </Checkbox>
                </>
              )}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  style={{ width: 150, marginTop: 4 }}
                  htmlType="submit"
                  loading={isTagAddingInProgress}
                  type="primary"
                  size="middle"
                  disabled={false}
                >
                  Create tag
                </Button>
                <Button
                  style={{ width: 150, marginTop: 4, marginLeft: 10 }}
                  onClick={() => setIsAddTag(false)}
                  type="default"
                  size="middle"
                  disabled={false}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
      <div style={{ padding: 20 }}>
        {isTagsLoadingInProgress && (
          <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
        )}
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          pageSize={50}
          defaultCurrent={1}
          total={totalTags}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
};
