import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_GETTING_CATEGORIES_IN_PROGRESS,
  SET_CATEGORIES,
  SET_IS_CREATING_BRAND_APPLIANCE_IN_PROGRESS,
  SET_ACTIVE_APPLIANCE,
  SET_BRAND_APPLIANCES_TO_ADD,
  ADD_CHECKED_CATEGORY,
  REMOVE_CHECKED_CATEGORY,
  ADD_CHECKED_APPLIANCE,
  REMOVE_CHECKED_APPLIANCE,
  ADD_CHECKED_BRAND_APPLIANCE,
  REMOVE_CHECKED_BRAND_APPLIANCE,
  REMOVE_CHECKED_BRAND_APPLIANCE_GROUP,
  ADD_CHECKED_BRAND_APPLIANCE_GROUP,
  SET_CHECKED_CATEGORIES,
  SET_CHECKED_APPLIANCES,
  SET_CHECKED_BRAND_APPLIANCES,
  SET_IS_CREATING_CATEGORY_IN_PROGRESS,
} from "../reducers/categoriesReducer";
import { notification } from "antd";
import { settingsApi } from "../../api";
import { ICategory } from "../../types";
import { getApplianceBrands } from "./applianceActions";

type SetIsGettingCategoriesInProgress = {
  type: typeof SET_IS_GETTING_CATEGORIES_IN_PROGRESS;
  payload: { isGettingCategoriesInProgress: boolean };
};

type SetIsCreatingcreatingBrandApplianceInProgress = {
  type: typeof SET_IS_CREATING_BRAND_APPLIANCE_IN_PROGRESS;
  payload: { isCreatingcreatingBrandApplianceInProgress: boolean };
};

type SetCategories = {
  type: typeof SET_CATEGORIES;
  payload: { categories: ICategory[] };
};

type SetActiveAppliance = {
  type: typeof SET_ACTIVE_APPLIANCE;
  payload: { activeAppliance: string | null };
};

type SetCheckedCategories = {
  type: typeof SET_CHECKED_CATEGORIES;
  payload: { checkedCategories: string[] };
};

type SetCheckedAppliances = {
  type: typeof SET_CHECKED_APPLIANCES;
  payload: { checkedAppliances: string[] };
};

type SetCheckedBrandAppliances = {
  type: typeof SET_CHECKED_BRAND_APPLIANCES;
  payload: { checkedBrandAppliances: string[] };
};

type SetBrandAppliancesToAdd = {
  type: typeof SET_BRAND_APPLIANCES_TO_ADD;
  payload: { brandAppliancesToAdd: string[] };
};

type AddCheckedCategory = {
  type: typeof ADD_CHECKED_CATEGORY;
  checkedCategoryId: string;
};

type AddCheckedAppliance = {
  type: typeof ADD_CHECKED_APPLIANCE;
  checkedApplianceId: string;
};

type AddCheckedBrandAppliance = {
  type: typeof ADD_CHECKED_BRAND_APPLIANCE;
  checkedBrandApplianceId: string;
};

type AddCheckedBrandApplianceGroup = {
  type: typeof ADD_CHECKED_BRAND_APPLIANCE_GROUP;
  checkedBrandApplianceIds: string[];
};

type RemoveCheckedCategory = {
  type: typeof REMOVE_CHECKED_CATEGORY;
  checkedCategoryId: string;
};

type RemoveCheckedAppliance = {
  type: typeof REMOVE_CHECKED_APPLIANCE;
  checkedApplianceId: string;
};

type RemoveCheckedBrandAppliance = {
  type: typeof REMOVE_CHECKED_BRAND_APPLIANCE;
  checkedBrandApplianceId: string;
};

type RemoveCheckedBrandApplianceGroup = {
  type: typeof REMOVE_CHECKED_BRAND_APPLIANCE_GROUP;
  checkedBrandApplianceIds: string[];
};

type SetIsCreatingCategoryInProgress = {
  type: typeof SET_IS_CREATING_CATEGORY_IN_PROGRESS;
  payload: { isCreatingCategoryInProgress: boolean };
};

export type SettingsActionsTypes =
  | SetIsGettingCategoriesInProgress
  | SetCategories
  | SetIsCreatingcreatingBrandApplianceInProgress
  | SetActiveAppliance
  | SetBrandAppliancesToAdd
  | AddCheckedCategory
  | AddCheckedAppliance
  | RemoveCheckedCategory
  | RemoveCheckedAppliance
  | AddCheckedBrandAppliance
  | RemoveCheckedBrandAppliance
  | RemoveCheckedBrandApplianceGroup
  | AddCheckedBrandApplianceGroup
  | SetCheckedCategories
  | SetCheckedAppliances
  | SetCheckedBrandAppliances
  | SetIsCreatingCategoryInProgress;

export const setIsGettingCategoriesInProgress = (
  isGettingCategoriesInProgress: boolean
): SetIsGettingCategoriesInProgress => ({
  type: SET_IS_GETTING_CATEGORIES_IN_PROGRESS,
  payload: { isGettingCategoriesInProgress },
});

export const setIsCreatingCategoryInProgress = (
  isCreatingCategoryInProgress: boolean
): SetIsCreatingCategoryInProgress => ({
  type: SET_IS_CREATING_CATEGORY_IN_PROGRESS,
  payload: { isCreatingCategoryInProgress },
});

export const setIsCreatingcreatingBrandApplianceInProgress = (
  isCreatingcreatingBrandApplianceInProgress: boolean
): SetIsCreatingcreatingBrandApplianceInProgress => ({
  type: SET_IS_CREATING_BRAND_APPLIANCE_IN_PROGRESS,
  payload: { isCreatingcreatingBrandApplianceInProgress },
});

export const setCategories = (categories: ICategory[]): SetCategories => ({
  type: SET_CATEGORIES,
  payload: { categories },
});

export const setActiveAppliance = (
  activeAppliance: string | null
): SetActiveAppliance => ({
  type: SET_ACTIVE_APPLIANCE,
  payload: { activeAppliance },
});

export const setBrandAppliancesToAdd = (
  brandAppliancesToAdd: string[]
): SetBrandAppliancesToAdd => ({
  type: SET_BRAND_APPLIANCES_TO_ADD,
  payload: { brandAppliancesToAdd },
});

export const setCheckedCategories = (
  checkedCategories: string[]
): SetCheckedCategories => ({
  type: SET_CHECKED_CATEGORIES,
  payload: { checkedCategories },
});

export const setCheckedAppliances = (
  checkedAppliances: string[]
): SetCheckedAppliances => ({
  type: SET_CHECKED_APPLIANCES,
  payload: { checkedAppliances },
});

export const setCheckedBrandAppliances = (
  checkedBrandAppliances: string[]
): SetCheckedBrandAppliances => ({
  type: SET_CHECKED_BRAND_APPLIANCES,
  payload: { checkedBrandAppliances },
});

export const addCheckedCategory = (
  checkedCategoryId: string
): AddCheckedCategory => ({
  type: ADD_CHECKED_CATEGORY,
  checkedCategoryId,
});

export const addCheckedAppliance = (
  checkedApplianceId: string
): AddCheckedAppliance => ({
  type: ADD_CHECKED_APPLIANCE,
  checkedApplianceId,
});

export const addCheckedBrandAppliance = (
  checkedBrandApplianceId: string
): AddCheckedBrandAppliance => ({
  type: ADD_CHECKED_BRAND_APPLIANCE,
  checkedBrandApplianceId,
});

export const addCheckedBrandApplianceGroup = (
  checkedBrandApplianceIds: string[]
): AddCheckedBrandApplianceGroup => ({
  type: ADD_CHECKED_BRAND_APPLIANCE_GROUP,
  checkedBrandApplianceIds,
});

export const removeCheckedCategory = (
  checkedCategoryId: string
): RemoveCheckedCategory => ({
  type: REMOVE_CHECKED_CATEGORY,
  checkedCategoryId,
});

export const removeCheckedAppliance = (
  checkedApplianceId: string
): RemoveCheckedAppliance => ({
  type: REMOVE_CHECKED_APPLIANCE,
  checkedApplianceId,
});

export const removeCheckedBrandAppliance = (
  checkedBrandApplianceId: string
): RemoveCheckedBrandAppliance => ({
  type: REMOVE_CHECKED_BRAND_APPLIANCE,
  checkedBrandApplianceId,
});

export const removeCheckedBrandApplianceGroup = (
  checkedBrandApplianceIds: string[]
): RemoveCheckedBrandApplianceGroup => ({
  type: REMOVE_CHECKED_BRAND_APPLIANCE_GROUP,
  checkedBrandApplianceIds,
});

export type SettingsThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  SettingsActionsTypes
>;

export const getCategories =
  (): SettingsThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsGettingCategoriesInProgress(true));
      const response = await settingsApi.loadCategories();
      if (response.status === "success") {
        console.log("getCategories response", response);
        dispatch(setCategories(response.categories));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of categories",
      });
    } finally {
      dispatch(setIsGettingCategoriesInProgress(false));
    }
  };

export const createCategory =
  (name: string, type: string): SettingsThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCreatingCategoryInProgress(true));
      const response = await settingsApi.createCategory(name, type);
      if (response.status === "success") {
        dispatch(getCategories());
        notification.success({
          message: "Success",
          description: "Created",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error creating category",
      });
    } finally {
      dispatch(setIsCreatingCategoryInProgress(false));
    }
  };

export const updateCategory =
  (
    values: { [param: string]: string },
    categoryId: string
  ): SettingsThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCreatingCategoryInProgress(true));
      const response = await settingsApi.upateCategory(values, categoryId);
      if (response.status === "success") {
        dispatch(getCategories());
        notification.success({
          message: "Success",
          description: "Updated",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error update category",
      });
    } finally {
      dispatch(setIsCreatingCategoryInProgress(false));
    }
  };

export const createBrandAppliance =
  (): SettingsThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsCreatingcreatingBrandApplianceInProgress(true));
      const applianceId = getState().categories.activeAppliance;
      const brandAppliancesToAdd = getState().categories.brandAppliancesToAdd;
      if (applianceId) {
        const response = await settingsApi.newBrandAppliance(
          applianceId,
          brandAppliancesToAdd
        );
        if (response.status === "success") {
          dispatch(getApplianceBrands(applianceId));
        }
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error creating BrandAppliance",
      });
    } finally {
      dispatch(setIsCreatingcreatingBrandApplianceInProgress(false));
    }
  };
