import { ICallrailCompany, ICompany } from "../../types";
import { CompanyActionsTypes } from "../actions/companyActions";

export const IS_COMPANY_CREATING_IN_PROGRESS =
  "company/IS_COMPANY_CREATING_IN_PROGRESS";
export const SET_IS_COMPANIES_LOADING_IN_PROGRESS =
  "company/SET_IS_COMPANIES_LOADING_IN_PROGRESS";
export const SET_COMPANIES = "company/SET_COMPANIES";
export const SET_COMPANY = "company/SET_COMPANY";
export const IS_COMPANY_TRADE_UPDATE_IN_PROGRESS =
  "company/IS_COMPANY_TRADE_UPDATE_IN_PROGRESS";
export const IS_CALLRAILCOMPANIES_LOAD_IN_PROGRESS =
  "company/IS_CALLRAILCOMPANIES_LOAD_IN_PROGRESS";
export const SET_CALLRAIL_COMPANIES = "company/SET_CALLRAIL_COMPANIES";

const initialState = {
  isCompanyCreatingInProgress: false,
  isCompanyTradeUpdateInProgress: false,
  isCompaniesLoadingInProgress: false,
  isCallrailCompaniesLoadingInProgress: false,
  companies: [] as ICompany[],
  company: null as ICompany | null,
  callrailCompanies: [] as ICallrailCompany[],
};

type InitialStateType = typeof initialState;

export const companyReducer = (
  state = initialState,
  action: CompanyActionsTypes
): InitialStateType => {
  switch (action.type) {
    case IS_COMPANY_CREATING_IN_PROGRESS:
    case SET_IS_COMPANIES_LOADING_IN_PROGRESS:
    case SET_COMPANIES:
    case SET_COMPANY:
    case IS_COMPANY_TRADE_UPDATE_IN_PROGRESS:
    case IS_CALLRAILCOMPANIES_LOAD_IN_PROGRESS:
    case SET_CALLRAIL_COMPANIES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
