import {
  Form,
  Input,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, message, Upload } from "antd";
import { mainUrl } from "../helpers/constants";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import {
  TemplateThunkType,
  deleteTemplate,
  getTemplateTypes,
  getTemplates,
} from "../store/actions/templateActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";
import { getBeautifullDate } from "../helpers/helpers";
import { FieldType } from "../types";
import { useSearchParams } from "react-router-dom";

export const Templates = () => {
  const [form] = Form.useForm();
  const { Title, Link } = Typography;
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentSearchParams, setCurrentSearchParams] = useState<{
    [param: string]: string;
  }>();
  const [formattedTemplateTypes, setFormattedTemplateTypes] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const dispatch = useDispatch() as Dispatch<TemplateThunkType | AnyAction>;
  const templates = useSelector(
    (state: AppStateType) => state.template.templates
  );
  const isTemplateDeleteInProgress = useSelector(
    (state: AppStateType) => state.template.isTemplateDeleteInProgress
  );

  const templateTypes = useSelector(
    (state: AppStateType) => state.template.templateTypes
  );
  const totalTemplates = useSelector(
    (state: AppStateType) => state.template.totalTemplates
  );

  const props: UploadProps = {
    name: "file",
    action: `${mainUrl}api/v1/template/upload`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    beforeUpload: (file) => {
      const isZIP = file.type === "application/zip";
      if (!isZIP) {
        message.error(`${file.name} is not a zip file`);
      }
      return isZIP || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log("info.file", info.file);
      console.log(info.fileList);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        dispatch(
          getTemplates(
            currentSearchParams?.page ? Number(currentSearchParams?.page) : 1,
            currentSearchParams?.type || "all",
            currentSearchParams?.palette || "all",
            currentSearchParams?.id || "all"
          )
        );
      } else if (info.file.status === "error") {
        if (info.file?.response?.message) {
          message.error(info.file?.response?.message);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
  };

  useEffect(() => {
    dispatch(
      getTemplates(
        currentSearchParams?.page ? Number(currentSearchParams?.page) : 1,
        currentSearchParams?.type || "all",
        currentSearchParams?.palette || "all",
        currentSearchParams?.id || "all"
      )
    );
    dispatch(getTemplateTypes());
  }, [
    currentSearchParams?.page,
    currentSearchParams?.type,
    currentSearchParams?.palette,
    currentSearchParams?.id,
  ]);

  useEffect(() => {
    setCurrentSearchParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  useEffect(() => {
    const cats = [{ value: "all", label: "all" }] as {
      value: string;
      label: string;
    }[];
    templateTypes.forEach((b) => {
      cats.push({
        value: b.type,
        label: b.type,
      });
    });
    setFormattedTemplateTypes(cats);
  }, [templateTypes]);

  useEffect(() => {
    setColumns([
      {
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <div>ID</div>
            <Form
              name="form_template_id"
              fields={[
                {
                  name: ["templateId"],
                  value: currentSearchParams?.id || "",
                },
              ]}
            >
              <Form.Item style={{ margin: 0, padding: 0 }} name="templateId">
                <Input
                  allowClear
                  style={{ width: 140 }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setSearchParams({
                        ...currentSearchParams,
                        id: e.target.value,
                        page: "1",
                      });
                    } else {
                      searchParams.delete("id");
                      setSearchParams(searchParams);
                    }
                  }}
                  placeholder="Search by id"
                />
              </Form.Item>
            </Form>
          </div>
        ),
        dataIndex: "id",
        key: "id",
      },
      {
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              paddingTop: 10,
            }}
          >
            <div>Type</div>
            <div>
              <Form
                style={{ minWidth: 400 }}
                form={form}
                layout="vertical"
                //onFinish={onFinish}
                fields={[
                  {
                    name: ["filterType"],
                    value: currentSearchParams?.type || "",
                  },
                ]}
              >
                <Form.Item<FieldType> name="filterType">
                  <Select
                    placeholder="Select type"
                    showSearch
                    onChange={(val) => console.log(val)}
                    style={{ width: 270 }}
                    onSelect={(val) =>
                      setSearchParams({
                        ...currentSearchParams,
                        type: `${val}`,
                        page: "1",
                      })
                    }
                    //style={{ width: 200, marginBottom: 20 }}
                    options={formattedTemplateTypes}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        ),
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Palette",
        dataIndex: "palette",
        key: "palette",
      },
      {
        title: "Created",
        dataIndex: "created",
        key: "created",
      },
      {
        title: "Updated",
        dataIndex: "updated",
        key: "updated",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
      },
    ]);
  }, [currentSearchParams, formattedTemplateTypes]);

  useEffect(() => {
    const ds = [] as any[];
    templates.forEach((t) => {
      ds.push({
        key: t._id,
        id: <Link href={`/templates/${t._id}`}>{t._id}</Link>,
        type: <Tag color="magenta">{t.type.toUpperCase()}</Tag>,
        palette:
          t.palette === "dark" ? (
            <Tag color="black">{t.palette.toUpperCase()}</Tag>
          ) : (
            <Tag color="default">{t.palette.toUpperCase()}</Tag>
          ),
        created: getBeautifullDate(t.createdAt),
        updated: getBeautifullDate(t.updatedAt),
        action: (
          <Popconfirm
            title="Delete"
            description="Are you sure to delete?"
            onConfirm={() =>
              dispatch(
                deleteTemplate(
                  t._id,
                  currentSearchParams?.page
                    ? Number(currentSearchParams?.page)
                    : 1,
                  currentSearchParams?.type || "all",
                  currentSearchParams?.palette || "all"
                )
              )
            }
          >
            <Button
              style={{ color: "red" }}
              disabled={isTemplateDeleteInProgress}
              type="link"
              size="middle"
            >
              Delete
            </Button>
          </Popconfirm>
        ),
      });
    });
    setDataSource(ds);
  }, [templates]);

  const onPageChange = (current: number) => {
    setSearchParams({ ...currentSearchParams, page: `${current}` });
  };

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 10,
        flexDirection: "column",
      }}
    >
      <Title level={3}>Upload template chunk</Title>
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>Upload .zip</Button>
      </Upload>
      <Title level={3}>Templates</Title>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <Pagination
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={20}
        pageSizeOptions={[20]}
        defaultCurrent={Number(currentSearchParams?.page) || 1}
        total={totalTemplates}
        onChange={onPageChange}
      />
    </div>
  );
};
