import { useDispatch, useSelector } from "react-redux";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Button, Modal, Popconfirm, Typography } from "antd";
import { AppStateType } from "../store";
import { SitesPreloader } from "../components/sites/SitesPreloader";

import { CreateNewSite } from "../components/sites/CreateNewSite";
import { Dispatch, useEffect, useState } from "react";
import {
  SiteThunkType,
  deleteSites,
  getSites,
  rebuildWithNewContent,
  setInProgressSiteId,
  updateSite,
} from "../store/actions/siteActions";
import { AnyAction } from "redux";
import { SitesList } from "../components/sites/SitesList";
import { getStates } from "../store/actions/locationActions";
import { getCategories } from "../store/actions/categoriesActions";
import { IOnInProgressId, IOnSitesUpdate } from "../types";
// eslint-disable-next-line react-hooks/rules-of-hooks

export const Sites = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedSites, setCheckedSites] = useState<string[]>([]);

  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const { Title } = Typography;

  const isGettingSitesInProgress = useSelector(
    (state: AppStateType) => state.site.isGettingSitesInProgress
  );

  const sites = useSelector((state: AppStateType) => state.site.sites);
  const sortByField = useSelector(
    (state: AppStateType) => state.site.sortByField
  );
  const sortBy = useSelector((state: AppStateType) => state.site.sortBy);
  const page = useSelector((state: AppStateType) => state.site.page);
  const serchByName = useSelector(
    (state: AppStateType) => state.site.serchByName
  );
  const serchByState = useSelector(
    (state: AppStateType) => state.site.serchByState
  );
  const inProgressId = useSelector(
    (state: AppStateType) => state.site.inProgressId
  );
  const serchByCity = useSelector(
    (state: AppStateType) => state.site.serchByCity
  );
  const serchByZip = useSelector(
    (state: AppStateType) => state.site.serchByZip
  );
  const serchByDomain = useSelector(
    (state: AppStateType) => state.site.serchByDomain
  );
  const serchByCompany = useSelector(
    (state: AppStateType) => state.site.serchByCompany
  );
  const serchByStatus = useSelector(
    (state: AppStateType) => state.site.serchByStatus
  );
  const isDeletionInProgress = useSelector(
    (state: AppStateType) => state.site.isDeletionInProgress
  );
  const socket = useSelector((state: AppStateType) => state.auth.socket);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    dispatch(getSites());
  }, [
    sortBy,
    sortByField,
    page,
    serchByName,
    serchByState,
    serchByCity,
    serchByZip,
    serchByDomain,
    serchByCompany,
    serchByStatus,
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onSitesUpdate = ({ siteId }: IOnSitesUpdate) => {
    console.log("siteId", siteId);
    if (siteId) {
      dispatch(getSites());
    }
  };

  const onInProgressId = ({ inProgressId }: IOnInProgressId) => {
    console.log("inProgressId", inProgressId);
    dispatch(setInProgressSiteId(inProgressId));
  };

  useEffect(() => {
    socket?.on("sitesUpdate", onSitesUpdate);
    socket?.on("inProgressId", onInProgressId);
  }, [socket]);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: 20,
          paddingTop: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title level={3}>Sites</Title>
            {checkedSites.length > 0 && (
              <div style={{ marginTop: 19, marginLeft: 10 }}>
                Selected: {checkedSites.length}
                <Popconfirm
                  title="Confirmation"
                  description={`Delete selected ${checkedSites.length} sites?`}
                  onConfirm={() => {
                    dispatch(deleteSites(checkedSites));
                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isDeletionInProgress}
                    style={{
                      background: "red",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <DeleteOutlined /> Delete
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Confirmation"
                  description={`Build/Rebuild selected ${checkedSites.length} sites?`}
                  onConfirm={async () => {
                    await checkedSites.reduce(
                      async (previousPromise, siteId) => {
                        await previousPromise;
                        dispatch(
                          updateSite(
                            {
                              status: "initial",
                              isInProgress: false,
                              statusAutoProgressOnCreate: true,
                              isDeployed: false,
                            },
                            siteId
                          )
                        );
                      },
                      Promise.resolve()
                    );

                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isDeletionInProgress}
                    style={{
                      background: "green",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <RocketOutlined /> Build/Rebuild
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Confirmation"
                  description={`Build/Rebuild with new content selected ${checkedSites.length} sites?`}
                  onConfirm={async () => {
                    await checkedSites.reduce(
                      async (previousPromise, siteId) => {
                        await previousPromise;
                        dispatch(rebuildWithNewContent(siteId));
                      },
                      Promise.resolve()
                    );

                    setCheckedSites([]);
                  }}
                >
                  <Button
                    loading={isDeletionInProgress}
                    style={{
                      background: "green",
                      color: "white",
                      border: 0,
                      marginLeft: 10,
                    }}
                    type="default"
                    onClick={() => null}
                  >
                    <RocketOutlined /> Rebuild with new content
                  </Button>
                </Popconfirm>
              </div>
            )}
            {isGettingSitesInProgress && (
              <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
            )}
          </div>
          <Button style={{ width: 180 }} type="primary" onClick={showModal}>
            <PlusOutlined /> Create
          </Button>
        </div>

        {sites.length === 0 && isGettingSitesInProgress && <SitesPreloader />}
        <SitesList
          checkedSites={checkedSites}
          setCheckedSites={setCheckedSites}
        />
      </div>
      <Modal
        title="Create new sites"
        open={isModalOpen}
        footer={[]}
        onCancel={() => setIsModalOpen(false)}
      >
        <CreateNewSite setIsModalOpen={setIsModalOpen} />
      </Modal>
    </>
  );
};
