import { Button, Form, FormProps, Input, Select } from "antd";
import React, { Dispatch } from "react";
import { FieldType } from "../../types";
import {
  ApplianceThunkType,
  createAppliance,
} from "../../store/actions/applianceActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import { createCategory } from "../../store/actions/categoriesActions";

export const CreateCategoryInput = () => {
  const dispatch = useDispatch() as Dispatch<ApplianceThunkType | AnyAction>;
  const [form] = Form.useForm();
  const isCreatingCategoryInProgress = useSelector(
    (state: AppStateType) => state.categories.isCreatingCategoryInProgress
  );
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.name && values.type) {
      dispatch(createCategory(values.name, values.type));
    }

    form.setFieldValue("name", "");
    form.setFieldValue("type", "");
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      //style={{ display: "flex", flexDirection: "column" }}
    >
      <Form.Item<FieldType> label="Category name" name="name">
        <Input placeholder="Category name" size="middle" />
      </Form.Item>
      <Form.Item<FieldType> label="Category type" name="type">
        <Select
          showSearch
          placeholder="Select type"
          optionFilterProp="children"
          options={[
            { value: "appliance", label: "appliance" },
            { value: "service", label: "service" },
          ]}
        />
      </Form.Item>
      <Button
        htmlType="submit"
        loading={isCreatingCategoryInProgress}
        type="primary"
        size="middle"
        disabled={false}
      >
        Create
      </Button>
    </Form>
  );
};
