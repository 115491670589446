import React, { Dispatch, useEffect, useState } from "react";
import {
  UnorderedListOutlined,
  LogoutOutlined,
  ApartmentOutlined,
  ShopOutlined,
  LayoutOutlined,
  BugOutlined,
  TagsOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu, Typography } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import {
  AuthThunkType,
  checkIsTokenValid,
  getAllUsers,
  logoutUser,
  setSocket,
} from "../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../store";
import { mainUrl } from "../helpers/constants";
import io from "socket.io-client";
import { AnyAction } from "redux";

type MenuItem = Required<MenuProps>["items"][number];

const Root: React.FC = () => {
  const dispatch = useDispatch() as Dispatch<AuthThunkType | AnyAction>;
  const location = useLocation();
  const { Link } = Typography;
  const user = useSelector((state: AppStateType) => state.auth.user);
  const access = user?.access?.split(",");

  const items: MenuItem[] = [];

  if (access?.includes("all") || access?.includes("sites")) {
    items.push({
      label: <Link href="/sites">Sites</Link>,
      key: "sites",
      icon: <UnorderedListOutlined />,
    });
  }
  if (access?.includes("all") || access?.includes("companies")) {
    items.push({
      label: <Link href="/companies">Companies</Link>,
      key: "companies",
      icon: <ShopOutlined />,
    });
  }
  if (access?.includes("all") || access?.includes("categories")) {
    items.push({
      label: <Link href="/categories">Categories</Link>,
      key: "categories",
      icon: <ApartmentOutlined />,
    });
  }

  if (access?.includes("all") || access?.includes("templates")) {
    items.push({
      label: <Link href="/templates">Templates</Link>,
      key: "templates",
      icon: <LayoutOutlined />,
    });
  }

  if (access?.includes("all") || access?.includes("tags")) {
    items.push({
      label: <Link href="/tags">Tags</Link>,
      key: "tags",
      icon: <TagsOutlined />,
    });
  }
  if (access?.includes("all") || access?.includes("images")) {
    items.push({
      label: <Link href="/images">Images</Link>,
      key: "images",
      icon: <FileImageOutlined />,
    });
  }
  if (access?.includes("all") || access?.includes("logs")) {
    items.push({
      label: <Link href="/logs">Logs</Link>,
      key: "logs",
      icon: <BugOutlined />,
    });
  }

  items.push({
    label: <span style={{ color: "red" }}>Logout</span>,
    key: "logout",
    onClick: () => dispatch(logoutUser()),
    icon: <LogoutOutlined color="red" style={{ color: "red" }} />,
  });

  const [current, setCurrent] = useState("sites");

  const isAppInit = useSelector((state: AppStateType) => state.auth.isAppInit);
  const isAuth = useSelector((state: AppStateType) => state.auth.isAuth);
  const socket = useSelector((state: AppStateType) => state.auth.socket);

  useEffect(() => {
    dispatch(checkIsTokenValid());
  }, []);

  useEffect(() => {
    console.log("location", location.pathname);
    if (location.pathname === "/") {
      setCurrent("sites");
    }
    if (location.pathname.includes("sites")) {
      setCurrent("sites");
    } else if (location.pathname.includes("companies")) {
      setCurrent("companies");
    } else if (location.pathname.includes("categories")) {
      setCurrent("categories");
    } else if (location.pathname.includes("templates")) {
      setCurrent("templates");
    } else if (location.pathname.includes("logs")) {
      setCurrent("logs");
    } else if (location.pathname.includes("tags")) {
      setCurrent("tags");
    } else if (location.pathname.includes("images")) {
      setCurrent("images");
    }
  }, [location]);

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  useEffect(() => {
    if (isAuth && !socket) {
      const socketInst = io(mainUrl, {
        transports: ["websocket"],
        autoConnect: true,
        forceNew: true,
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: Infinity,
      });
      dispatch(setSocket(socketInst));
    }
    return () => {
      socket?.disconnect();
    };
  }, [isAuth, socket]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getAllUsers());
    }
  }, [isAuth]);

  return isAppInit ? (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
      <Outlet />
    </>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <p style={{ fontSize: 16 }}>Loading...</p>
    </div>
  );
};

export default Root;
