import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "./reducers/authReducer";
import { siteReducer } from "./reducers/siteReducer";
import { locationReducer } from "./reducers/locationReducer";
import { applianceReducer } from "./reducers/applianceReducer";
import { categoriesReducer } from "./reducers/categoriesReducer";
import { companyReducer } from "./reducers/companyReducer";
import { templateReducer } from "./reducers/templateReducer";
import { logReducer } from "./reducers/logReducer";
import { tagReducer } from "./reducers/tagReducer";
import { imageReducer } from "./reducers/imageReducer";
import { domainReducer } from "./reducers/domainReducer";

const initialState = {};

const rootReducer = combineReducers({
  auth: authReducer,
  site: siteReducer,
  location: locationReducer,
  appliance: applianceReducer,
  categories: categoriesReducer,
  company: companyReducer,
  template: templateReducer,
  log: logReducer,
  tag: tagReducer,
  image: imageReducer,
  domain: domainReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;

export default createStore(rootReducer, initialState, applyMiddleware(thunk));
