import { Button, Form, FormProps, Input, Select } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { FieldType, ICategory } from "../../types";
import {
  ApplianceThunkType,
  createAppliance,
} from "../../store/actions/applianceActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import {
  createCategory,
  updateCategory,
} from "../../store/actions/categoriesActions";

interface IProps {
  activeCategory: string;
}

export const EditMainCategoryInput = ({ activeCategory }: IProps) => {
  const [category, setCategory] = useState<ICategory | null>(null);
  const dispatch = useDispatch() as Dispatch<ApplianceThunkType | AnyAction>;
  const [form] = Form.useForm();
  const isCreatingCategoryInProgress = useSelector(
    (state: AppStateType) => state.categories.isCreatingCategoryInProgress
  );
  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.name && values.type) {
      dispatch(updateCategory(values, activeCategory));
    }

    form.setFieldValue("name", "");
    form.setFieldValue("type", "");
  };

  useEffect(() => {
    let cat = categories?.find((cat) => cat._id === activeCategory);
    if (cat) {
      setCategory(cat);
    } else {
      setCategory(null);
    }
  }, [activeCategory, categories]);

  useEffect(() => {
    form.setFieldValue("name", category?.name);
    form.setFieldValue("type", category?.type);
  }, [category]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      //style={{ display: "flex", flexDirection: "column" }}
    >
      <Form.Item<FieldType> label="Category name" name="name">
        <Input placeholder="Category name" size="middle" />
      </Form.Item>
      <Form.Item<FieldType> label="Category type" name="type">
        <Select
          showSearch
          placeholder="Select type"
          optionFilterProp="children"
          options={[
            { value: "appliance", label: "appliance" },
            { value: "service", label: "service" },
          ]}
        />
      </Form.Item>
      <Button
        htmlType="submit"
        loading={isCreatingCategoryInProgress}
        type="primary"
        size="middle"
        disabled={false}
      >
        Save
      </Button>
    </Form>
  );
};
