import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStateType } from "../store";

export const Main = () => {
  let navigate = useNavigate();
  const isAuth = useSelector((state: AppStateType) => state.auth.isAuth);
  const user = useSelector((state: AppStateType) => state.auth.user);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    } else {
      console.log("user", user);
      if (user) {
        if (user?.email === "arman@arman.com") {
          navigate("/images");
        } else {
          navigate("/sites");
        }
      }
    }
  }, [isAuth, user]);

  return null;
};
