import { ICategory } from "../../types";
import { SettingsActionsTypes } from "../actions/categoriesActions";

export const SET_IS_GETTING_CATEGORIES_IN_PROGRESS =
  "settings/SET_IS_GETTING_CATEGORIES_IN_PROGRESS";
export const SET_CATEGORIES = "settings/SET_CATEGORIES";
export const SET_IS_CREATING_BRAND_APPLIANCE_IN_PROGRESS =
  "settings/SET_IS_CREATING_BRAND_APPLIANCE_IN_PROGRESS";
export const SET_ACTIVE_APPLIANCE = "settings/SET_ACTIVE_APPLIANCE";
export const SET_BRAND_APPLIANCES_TO_ADD =
  "settings/SET_BRAND_APPLIANCES_TO_ADD";
export const ADD_CHECKED_CATEGORY = "settings/ADD_CHECKED_CATEGORY";
export const REMOVE_CHECKED_CATEGORY = "settings/REMOVE_CHECKED_CATEGORY";
export const ADD_CHECKED_APPLIANCE = "settings/ADD_CHECKED_APPLIANCE";
export const REMOVE_CHECKED_APPLIANCE = "settings/REMOVE_CHECKED_APPLIANCE";
export const ADD_CHECKED_BRAND_APPLIANCE =
  "settings/ADD_CHECKED_BRAND_APPLIANCE";
export const REMOVE_CHECKED_BRAND_APPLIANCE =
  "settings/REMOVE_CHECKED_BRAND_APPLIANCE";
export const REMOVE_CHECKED_BRAND_APPLIANCE_GROUP =
  "settings/REMOVE_CHECKED_BRAND_APPLIANCE_GROUP";
export const ADD_CHECKED_BRAND_APPLIANCE_GROUP =
  "settings/ADD_CHECKED_BRAND_APPLIANCE_GROUP";
export const SET_CHECKED_CATEGORIES = "settings/SET_CHECKED_CATEGORIES";
export const SET_CHECKED_APPLIANCES = "settings/SET_CHECKED_APPLIANCES";
export const SET_CHECKED_BRAND_APPLIANCES =
  "settings/SET_CHECKED_BRAND_APPLIANCES";
export const SET_IS_CREATING_CATEGORY_IN_PROGRESS =
  "settings/SET_IS_CREATING_CATEGORY_IN_PROGRESS";

const initialState = {
  isGettingCategoriesInProgress: false,
  isCreatingCategoryInProgress: false,
  categories: [] as ICategory[],
  isCreatingcreatingBrandApplianceInProgress: false,
  activeAppliance: null as null | string,
  brandAppliancesToAdd: [] as string[],
  checkedCategories: [] as string[],
  checkedAppliances: [] as string[],
  checkedBrandAppliances: [] as string[],
};

type InitialStateType = typeof initialState;

export const categoriesReducer = (
  state = initialState,
  action: SettingsActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_GETTING_CATEGORIES_IN_PROGRESS:
    case SET_CATEGORIES:
    case SET_IS_CREATING_BRAND_APPLIANCE_IN_PROGRESS:
    case SET_ACTIVE_APPLIANCE:
    case SET_BRAND_APPLIANCES_TO_ADD:
    case SET_CHECKED_CATEGORIES:
    case SET_CHECKED_APPLIANCES:
    case SET_CHECKED_BRAND_APPLIANCES:
    case SET_IS_CREATING_CATEGORY_IN_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_CHECKED_CATEGORY:
      return {
        ...state,
        checkedCategories: !state.checkedCategories.includes(
          action.checkedCategoryId
        )
          ? [...state.checkedCategories, action.checkedCategoryId]
          : state.checkedCategories,
      };
    case ADD_CHECKED_APPLIANCE:
      return {
        ...state,
        checkedAppliances: !state.checkedAppliances.includes(
          action.checkedApplianceId
        )
          ? [...state.checkedAppliances, action.checkedApplianceId]
          : state.checkedAppliances,
      };
    case ADD_CHECKED_BRAND_APPLIANCE:
      return {
        ...state,
        checkedBrandAppliances: !state.checkedBrandAppliances.includes(
          action.checkedBrandApplianceId
        )
          ? [...state.checkedBrandAppliances, action.checkedBrandApplianceId]
          : state.checkedBrandAppliances,
      };
    case ADD_CHECKED_BRAND_APPLIANCE_GROUP:
      return {
        ...state,
        checkedBrandAppliances: [
          ...state.checkedBrandAppliances.filter(
            (cba) => !action.checkedBrandApplianceIds.includes(cba)
          ),
          ...action.checkedBrandApplianceIds,
        ],
      };
    case REMOVE_CHECKED_CATEGORY:
      return {
        ...state,
        checkedCategories: [
          ...state.checkedCategories.filter(
            (cc) => cc !== action.checkedCategoryId
          ),
        ],
      };
    case REMOVE_CHECKED_APPLIANCE:
      return {
        ...state,
        checkedAppliances: [
          ...state.checkedAppliances.filter(
            (cc) => cc !== action.checkedApplianceId
          ),
        ],
      };
    case REMOVE_CHECKED_BRAND_APPLIANCE:
      return {
        ...state,
        checkedBrandAppliances: [
          ...state.checkedBrandAppliances.filter(
            (cc) => cc !== action.checkedBrandApplianceId
          ),
        ],
      };
    case REMOVE_CHECKED_BRAND_APPLIANCE_GROUP:
      return {
        ...state,
        checkedBrandAppliances: [
          ...state.checkedBrandAppliances.filter(
            (cc) => !action.checkedBrandApplianceIds.includes(cc)
          ),
        ],
      };
    default:
      return state;
  }
};
