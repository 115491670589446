import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  IS_DOMAINS_LOADING_IN_PROGRESS,
  SET_DOMAINS,
  IS_DOMAINS_CREATING_IN_PROGRESS,
} from "../reducers/domainReducer";
import { domainApi } from "../../api";
import { notification } from "antd";
import { IDomain } from "../../types";
import { isValidDomain } from "../../helpers/helpers";

type SetIsDomainsLoadingInProgress = {
  type: typeof IS_DOMAINS_LOADING_IN_PROGRESS;
  payload: { isDomainsLoadingInProgress: boolean };
};

type SetDomains = {
  type: typeof SET_DOMAINS;
  payload: { domains: IDomain[] };
};

type SetIsDomainsCreatingInProgress = {
  type: typeof IS_DOMAINS_CREATING_IN_PROGRESS;
  payload: { isDomainsCreatingInProgress: boolean };
};

export type DomainActionsTypes =
  | SetIsDomainsLoadingInProgress
  | SetDomains
  | SetIsDomainsCreatingInProgress;

export const setIsDomainsLoadingInProgress = (
  isDomainsLoadingInProgress: boolean
): SetIsDomainsLoadingInProgress => ({
  type: IS_DOMAINS_LOADING_IN_PROGRESS,
  payload: { isDomainsLoadingInProgress },
});

export const setIsDomainsCreatingInProgress = (
  isDomainsCreatingInProgress: boolean
): SetIsDomainsCreatingInProgress => ({
  type: IS_DOMAINS_CREATING_IN_PROGRESS,
  payload: { isDomainsCreatingInProgress },
});

export const setDomains = (domains: IDomain[]): SetDomains => ({
  type: SET_DOMAINS,
  payload: { domains },
});

export type DomainThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  DomainActionsTypes
>;

export const getDomainsByCategory =
  (category: string): DomainThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDomainsLoadingInProgress(true));
      const response = await domainApi.getDomainsByCategory(category);
      if (response.status === "success") {
        dispatch(setDomains(response.domains));
        console.log("response", response);
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading domains",
      });
    } finally {
      dispatch(setIsDomainsLoadingInProgress(false));
    }
  };

export const addDomains =
  (domains: string[], categoryId: string): DomainThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsDomainsCreatingInProgress(true));
      await domains.reduce(async (previousPromise, domain) => {
        await previousPromise;
        const trimmedDomain = domain.trim();
        if (isValidDomain(trimmedDomain)) {
          await domainApi.createDomain({
            domain: trimmedDomain,
            category: categoryId,
          });
        }
      }, Promise.resolve());
      dispatch(getDomainsByCategory(categoryId));
      notification.success({
        message: "Success",
        description: "Added",
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error adding domains",
      });
    } finally {
      dispatch(setIsDomainsCreatingInProgress(false));
    }
  };
