export const getBeautifullDate = (date: string) => {
  return `${new Date(Date.parse(date)).toLocaleDateString("en-US")} ${
    new Date(Date.parse(date)).toLocaleTimeString("en-US")?.split(":")[0]
  }:${
    new Date(Date.parse(date))
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      ?.split(":")[1]
  }`;
};

export function isValidDomain(domain: string) {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
}

export function timeAgo(dateString: string): string {
  const inputDate = new Date(dateString);
  const now = new Date();

  // Проверка на валидность даты
  if (isNaN(inputDate.getTime())) {
    throw new Error("Invalid date format");
  }

  const differenceInMs = now.getTime() - inputDate.getTime();
  const seconds = Math.floor(differenceInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Условная длина месяца
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks < 4) {
    return `${weeks} weeks ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else {
    return `${years} years ago`;
  }
}

// Пример использования
console.log(timeAgo("2024-09-05T00:13:30.093+00:00"));
